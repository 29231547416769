import React from 'react';
import { SocialIcon } from "react-social-icons";
import "./Home.css";
import { NavLink } from "react-router-dom";
import About from "./About.js";
import WhatIDo from "./WhatIDo.js";
import Contact from "./Contact.js";
import Footer from "./Footer.js";

const Home = () => {
  return (
    <div>
      <section className="hero">
        <div className="hero_text">
          <h1 className="intro">Hey There, <br /> I'm Chisom Winifred.</h1>
            <p>I'm a digital marketer, content creator and copywriter with 4+ years of experience crafting compelling copy and engaging brand content.</p>
            <div className="web__btn__grp">
                <NavLink to="/portfolio">
                <button className="work__btn">My Work ⚒</button>
              </NavLink>
              <NavLink to="/blog">
                <button className="work__btn btn">Blog 📝</button>
              </NavLink>
            </div>
            <h4>Check out my  
                <SocialIcon className="socials" url="https://twitter.com/chisomwinnifred" target="_blank" fgColor="#f1f1f1" bgColor="#333" style={{height: 30, width: 30 }} />
                <SocialIcon className="socials" url="https://instagram.com/chisomwinifred" target="_blank" fgColor="#f1f1f1" bgColor="#333" style={{height: 30, width: 30 }} />
                <SocialIcon className="socials" url="https://facebook.com/chisom.asogwa.5" target="_blank" fgColor="#f1f1f1" bgColor="#333" style={{height: 30, width: 30 }} />
            </h4> 
          </div>
          <div class="hero__img" data-aos="fade-left">
              <div class="img-container"></div>
          </div>
      </section>
      <About />
      <WhatIDo />
      <Contact />
      <Footer />
    </div>
  )
}

export default Home;