import React from 'react';
import "./WhatIDo.css";

const WhatIDo = () => {
  return (
    <div>
      <section id="skills" className="skills">
        <div className="skill__head__text" data-aos="fade-in">
            <h1>What I Do</h1>
            <p>I believe that story telling through the media is one of the most potent ways to put ideas into the world and thus I intend to contribute my quota.</p>
        </div>
        <div className="skill">
            <h1 className="skill__header" data-aos="fade-in">Skills</h1>
            <h1 className="skillset" data-aos="fade-up"><span>01/</span> Content Writing</h1>
            <h1 className="skillset" data-aos="fade-up"><span>02/</span> Digital Marketing</h1>
            <h1 className="skillset" data-aos="fade-up"><span>03/</span> Copywriting</h1>
        </div>
    </section>
    </div>
  )
}

export default WhatIDo