import React from 'react'
import Contact from "./Contact.js";
import Footer from "./Footer.js";
import "./Portfolio.css";
import Access from "../portfolio/Access Pan African Awards.pdf";
import Amstel from "../portfolio/Amstel Malta Article.pdf";
import EnuguFood from "../portfolio/Enugu Food Meets Fashion.pdf";
import Fidelity from "../portfolio/Fidelity corporate banking.pdf";
import Mtn from "../portfolio/MTN Article.pdf";
import Personal from "../portfolio/Personal statement.pdf";
import MediaMoney from "../portfolio/THE MEDIA MONEY OF LAGOS.pdf";
import ViacomCBS from "../portfolio/Thought leadership.pdf";
import Trendupp from "../portfolio/Trendupp Awards Official Release.pdf";
import Trophy from "../portfolio/Trophy Episode 7.pdf";

const Portfolio = () => {
  return (
    <div>
      <section id="portfolio" class="portfolio">
        <h1 class="portfolio__head__text">My Portfolio</h1>
        <p>Featured below are a few articles, projects and campaigns I've written copy and created content for.</p>
        <div class="divider"></div>
        <div class="row" data-aos="fade-up">
            <a target="_blank" href="https://www.bellanaija.com/author/chisom-winifred">
                <div class="column">
                    <i class="fas fa-pen-fancy"></i>
                    <h2>Featured writer on Bellanaija.</h2>
                </div>
            </a>
            <a target="_blank" href="https://glamafrica.com/author/chisom-winifred">
                <div class="column">
                    <i class="fas fa-pen-fancy"></i>
                    <h2>Beauty editor at Glam Africa magazine.</h2>
                </div>
            </a>
            <a target="_blank" href={Access}>
                <div class="column">
                    <i class="far fa-newspaper"></i>
                    <h2>Featured article for Nigerian Multinational Commercial Bank, Access Bank PLC.</h2>
                </div>
            </a>
            <a target="_blank" href={Fidelity}>
                <div class="column">
                    <i class="far fa-newspaper"></i>
                    <h2>Featured article for Fidelity Bank Plc, a commercial bank in Nigeria.</h2>
                </div>
            </a>
        </div>
        <div class="row" data-aos="fade-up">
            <a target="_blank" href={ViacomCBS}>
                <div class="column">
                    <i class="far fa-newspaper"></i>
                    <h2>Thought leadership article for ViacomCBS Networks Africa.</h2>
                </div>
            </a>
            <a target="_blank" href={Trophy}>
                <div class="column">
                    <i class="far fa-newspaper"></i>
                    <h2>Promotion Article for Trophy Extra Special Band Season 2.</h2>
                </div>
            </a>
            <a target="_blank" href={Personal}>
                <div class="column">
                    <i class="fas fa-utensils"></i>
                    <h2>Personal statement for international chef Soliat Bada.</h2>
                </div>
            </a>
            <a target="_blank" href={EnuguFood}>
                <div class="column">
                    <i class="fas fa-utensils"></i>
                    <h2>Editor for the first edition of Enugu Food Meets Fashion Magazine sponsored by Golden Penny Foods and Milo.</h2>
                </div>
            </a>
        </div>
        <div class="row" data-aos="fade-up">
            <a target="_blank" href={MediaMoney}>
                <div class="column">
                    <i class="fas fa-microphone"></i>
                    <h2>Speech for group CEO RED Media Africa Adebola Williams.</h2>
                </div>
            </a>
            <a target="_blank" href={Trendupp}>
                <div class="column">
                    <i class="fas fa-microphone"></i>
                    <h2>Press article for the maiden edition of Trendupp Africa; Nigeria’s First Influencers & Content Creators Award.</h2>
                </div>
            </a>
            <a target="_blank" href={Mtn}>
                <div class="column">
                    <i class="fas fa-microphone"></i>
                    <h2>Press article for MTN Nigeria.</h2>
                </div>
            </a>
            <a target="_blank" href={Amstel}>
                <div class="column">
                    <i class="fas fa-microphone"></i>
                    <h2>Press article for international malt brand, Amstel Malta.</h2>
                </div>
            </a>
        </div>
        <div class="row" data-aos="fade-up">
            <a target="_blank" href="https://www.hayat.media/loveshea-skincare-has-all-you-need-for-healthy-youthful-skin/">
                <div class="column">
                    <i class="fas fa-coins"></i>
                    <h2>Sales article for London based beauty brand, Loveshea skincare.</h2>
                </div>
            </a>
            <a target="_blank" href="https://www.hayat.media/glow-and-grow-with-skincare-products-from-mia-lueur-organic-support/">
                <div class="column">
                    <i class="fas fa-coins"></i>
                    <h2>Sales article for London based beauty brand, Mia Lueur.</h2>
                </div>
            </a>
            <a target="_blank" href="https://geohomesgroup.com/content/33-are-you-a-real-estate-artisan-heres-how-geohomes-can-ensure-your-next-payday">
                <div class="column">
                    <i class="fas fa-home"></i>
                    <h2>Blog post for Nigeria's Leading Real Estate Group Geohomes Housing.</h2>
                </div>
            </a>
            <a target="_blank" href="https://geohomesgroup.com/content/34-investing-in-properties-the-smart-way">
                <div class="column">
                    <i class="fas fa-home"></i>
                    <h2>Blog post for Nigeria's Leading Real Estate Group Geohomes Housing.</h2>
                </div>
            </a>
        </div>
    </section>
      <Contact />
      <Footer />
    </div>
  )
}

export default Portfolio