import React from 'react';
import chisom from "../images/chisomm.png";
import "./About.css";

const About = () => {
  return (
    <div>
      <section className="about" id="about">
        <div className="head__text">
            <h1>My Story</h1>
            <div className="divider"></div>
        </div>
        <div className="about__content">
            <div className="about__img" data-aos="flip-left">
                <img src={chisom} alt="" />
            </div>
            <div className="about__me"data-aos="fade-up">
                <h2>Digital Marketer, Content Creator and Copywriter</h2>
                <p>I am Chisom Winifred Asogwa, a digital marketer, content creator and copywriter. I’ve interned and worked with media firms where I honed my digital marketing skills such as storytelling RED Media Africa, West Africa’s foremost public relations and communications firm. I currently contribute to Bellanaija.com and Glamafrica.com as a featured writer on career, business and millennial lifestyle. <br/>
    
                I believe that story telling through the media is one of the most potent ways to put ideas into the world and thus I intend to contribute my quota.</p>
                <h3>From Biochemistry to Copywriting</h3>
                <p>Despite being a graduate of Applied Biochemistry from the Enugu State University of Science and Technology, this has never debarred me from making tangible progress in the media and copywriting industry. I fancy myself a creative person and I love the challenge and fulfilment I gain from writing. I love and appreciate the potency of words, it's ability to convince and convict. I truly believe that stories are the only thing that will last forever.</p>
            </div>
        </div> 
    </section>
    </div>
  )
}

export default About;