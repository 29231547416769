import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import SanityBlockContent from "@sanity/block-content-to-react";
import "./SinglePost.css";
// import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const SinglePost = () => {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        "name": author->name, 
        "categories": categories[]->title,
        body,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => {
        setSinglePost(
          data.filter((element) => element.slug.current === slug.split(":")[1])
        );
        console.log("____---------------", data);
      })
      .catch(console.error);
  }, [slug]);

  return (
    <div>
      {singlePost ? (
        singlePost &&
        singlePost.map((item) => {
          return (
            <article>
              <header>
                <div>
                  <div>
                    <div className="header">
                      <img src={urlFor(item.mainImage.asset.url)} alt={item.name} className="main__image" />
                      <div className="author">
                        <h1>{item.title}</h1>
                        <p>Author: {item.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div className="body">
                <SanityBlockContent blocks={item.body} projectId="plc7ubfg" dataset="production"/>
              </div>
            </article>
          );
        })
      ) : (
        <h2>No posts here 🤷🏿‍♀️</h2>
      )}
    </div>
  );
};

export default SinglePost;