import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import "./Post.css";
import Footer from "./Footer";


const Post = () => {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        "name": author->name, 
        "categories": categories[]->title,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <h2 className="blog__text">Blog Posts</h2>
      <p className="blog__text">I write sometimes, below are some of my articles.</p>
      <div className="divider"></div>
      <div className="blog">
        <article>
          {allPostsData &&
            allPostsData.map((post, index) => (
              <Link className="post" to={"/post:" + post.slug.current} key={post.slug.current}>
                <span key={index}>
                  <img src={post.mainImage.asset.url} alt="" />
                  <span className="post__title">
                    <h2 className="text title">{post.title}</h2>
                    <p className="text">By {post.name}</p>
                    <p className="text">Category: {post.categories}</p> 
                  </span>
                  <Link to={"/post:" + post.slug.current}>
                    <button>Read more</button>
                  </Link>
                </span>
              </Link>
            ))}
        </article>
      </div>
      <Footer />
    </div>
  );
}

export default Post