import React from 'react'
import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <section id="contact" className="contact">
        <h1>Work With Me</h1>
        <p>Have a product? let's make it popular. Have opportunities for me? let's discuss. Just wanna say hi? click the button below 😊</p>
        <a href="mailto:chisomwinnifred.cw@gmail.com"><button className="web__btn">Let's  Discuss 📩</button></a>
    </section>
    </div>
  )
}

export default Contact