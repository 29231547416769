import React from 'react'

const Footer = () => {
  return (
    <div>
      <section className="footer">
        <footer>
            <h1 className="logo footer__logo">Chisom.</h1>
            <p>Copyright &copy; { new Date().getFullYear()} Chisom Winifred</p>
        </footer>
    </section>
    </div>
  )
}

export default Footer;